import React from 'react'
import { Link } from "react-router-dom";


const PreDepartureService = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Pre-Departure Service</h3>
            </div>
          </div>
        </div>
      </section>

      <div className="carrer-page-section">
        <div className="container">
          <div className="vacancy-section">
            <div className="vacancy-container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="vacancy-content-wrap">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="vacancy-content">
                          <div className="pattern-overlay zigzag-patten" />
                          <p>
                            Congratulations for finally leaving for an amazing
                            new experience! Although it may seem overwhelming,
                            trust us—the best is yet to come. We are also here
                            to support you all on this journey. Before each
                            foreign intake, we have a variety of pre-departure
                            meetings with parents and students to help you
                            prepare for life in your new country.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="vacancy-content">
                          <div className="pattern-overlay c-patten" />
                          <p>
                            Attend one of our Pre-Departures, and we will make
                            all of your worries disappear. We'll help you look
                            into opportunities for social and personal growth
                            while you're away from home. We assist you by
                            providing a set agenda to mention before departing
                            India.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="vacancy-content">
                          <div className="pattern-overlay c-patten" />
                          <p>
                            We will walk you through the customs procedures,
                            immigration checks, and the easiest way to get from
                            the airport to your location. We will also inform
                            you of your rights as an international student and
                            the laws and regulations of the nation you have
                            selected.
                          </p>
                        </div>

                        <h5 className="section-title text-center">
                          Please fill out the adjacent form to schedule your
                          free counselling appointment.
                        </h5>
                        <Link to="/contact" className="button-round-secondary">
                          JOIN US NOW
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreDepartureService