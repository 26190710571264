import React from "react";

const AttendMockSession = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title"> Attend Mock Session</h3>
            </div>
          </div>
        </div>
      </section>

      <div className="checkout-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 right-sidebar">
              <div className="checkout-field-wrap">
                <h3 className="cheakout-title text-center">ATTEND A MOCK SESSION</h3>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        First Name <abbr className="label-star">*</abbr>
                      </label>
                      <input type="text" name="name" placeholder="First Name" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        last Name <abbr className="label-star">*</abbr>
                      </label>
                      <input type="text" name="name" placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Enter Email <abbr className="label-star">*</abbr>
                      </label>
                      <input
                        type="mail"
                        name="name"
                        placeholder="Enter Email"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Enter Mobile No. <abbr className="label-star">*</abbr>
                      </label>
                      <input
                        type="number"
                        name="name"
                        placeholder=" Enter Mobile No."
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Select Branch <abbr className="label-star">*</abbr>
                      </label>
                      <select>
                        <option value={0}>Borivali</option>
                        <option value={0}>Santacruz</option>
                        <option value={0}>Thane</option>
                        <option value={0}>Kothrud</option>
                        <option value={0}>Hinjewadi</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <label>
                      Enter Massage <abbr className="label-star">*</abbr>
                    </label>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className=" inner-form-group">
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter Massage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="coupon-field">
                    <label>
                      <a href="#">78765</a>
                    </label>
                    <div className="form-group">
                      <input
                        type="text"
                        name="coupon"
                        placeholder="Captcha Code"
                        className="coupon-txt"
                      />
                      <input
                        type="submit"
                        name="submit"
                        defaultValue="Apply coupon"
                        className="coupon-btn"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendMockSession;
