import React from "react";
import { Link } from "react-router-dom";


const CareerCounselling = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Career Counselling</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Committed To The Best Results. Join Us Now !
                  </h2>
                  <p className="feature-course-desc">
                    One of the most significant choices you will ever make in
                    your life is your career. Personalized career counselling
                    sessions are provided FREE of charge by Educasa. At Educasa,
                    our team of counsellors devote enough time to mentoring and
                    aiding you in realising and making decisions that will have
                    a good impact on your career, academic, and personal
                    choices.
                  </p>
                  <p className="feature-course-desc">
                    It is crucial to choose the appropriate career choice
                    because it determines your existence, your future, the type
                    of life you will lead, and the people with whom you will
                    deal. It should be obvious that your career has a
                    significant impact on your life. However, applying for
                    higher education abroad is a significant decision on a
                    financial and emotional level. So, it is crucial to set
                    aside enough time and consult a qualified counsellor in
                    order to make the best choices.
                  </p>
                  <p className="feature-course-desc">
                    By providing you with information on numerous prospects in
                    the fields of abroad education and global careers, our team
                    of counsellors assists you in strategically planning your
                    future through overseas education, investments, and perhaps
                    employment. Among our other services, we help families and
                    students apply for the EB5 visa so they can obtain permanent
                    residency in the United States of America for the entire
                    family.
                  </p>
                  <p className="mt-lg-4">
                    <b>
                      Please fill out the adjacent form to schedule your free
                      counselling appointment.
                    </b>
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/educator-img33.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default CareerCounselling;
