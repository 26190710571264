import React from 'react'
import { Link } from "react-router-dom";


const FinancialAssistance = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Financial Assistance</h3>
            </div>
          </div>
        </div>
      </section>

      <div className="carrer-page-section">
        <div className="container">
          <div className="vacancy-section">
            <div className="vacancy-container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="vacancy-content-wrap">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="vacancy-content">
                          <div className="pattern-overlay zigzag-patten" />
                          <p>
                            We recognise that studying abroad is an expensive
                            endeavour. We collaborate with our students'
                            families to comprehend their financial needs and
                            strengths in order to provide them with the best
                            options.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="vacancy-content">
                          <div className="pattern-overlay c-patten" />
                          <p>
                            When they arrive in their selected nation, we also
                            suggest to our students that they open a local bank
                            account as soon as possible. They now have simple
                            access to money for paying their bills and other
                            daily needs. This also makes it simpler for your
                            employer to deposit your paycheck if you decide to
                            work while you are a student.
                          </p>
                        </div>
                      </div>
                    </div>
                    <h5 className="section-title text-center">
                      Please fill out the adjacent form to schedule your free
                      counselling appointment.
                    </h5>
                    <Link
                      to="/contact"
                      className="button-round-secondary"
                    >
                      JOIN US NOW
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinancialAssistance