import React from "react";
import HomeBanner from "./home/HomeBanner";
import HomeAbout from "./home/HomeAbout";
import InternationalDestinations from "./home/InternationalDestinations";
import Committed from "./home/Committed";
import PopularCourses from "./home/PopularCourses";
import HomeServices from "./home/HomeServices";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <HomeAbout />
      <HomeServices />
      <InternationalDestinations />
      <Committed />
      <PopularCourses />
    </>
  );
};

export default Home;
