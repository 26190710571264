import React from "react";

const ParentGuide = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap ">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h1 className="inner-title">Parent Guide</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="carrer-page-section">
          <div className="about-service-wrap">
            <div className="pattern-overlay circle-patten" />
            <div className="container">
              <div className="section-head">
                <div className="row no-gutters align-items-end">
                  <div className="col-lg-6">
                    <div className="title-divider" />
                    <h5 className="section-title">
                      One of the most significant and courageous choices you
                      will ever make, parents, is this. We comprehend that
                      letting your child leave the nest is not an easy option.
                    </h5>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-disc">
                      <p>
                        It is your duty to assist your child in making this
                        choice and to participate in the entire process.
                      </p>
                      <p>
                        As a parent, your responsibility is to comprehend your
                        child. In order to ensure that your children make the
                        proper decisions in their daily lives, you as their
                        parent need to be an active part of their dynamic
                        strategy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iconbox-container d-flex flex-wrap justify-content-center">
                <div className="iconbox-item-3 d-flex flex-wrap">
                  <div className="iconbox-border d-flex align-items-center">
                    <div className="iconbox-content ">
                      <h4>Discuss the objectives Transparently</h4>
                      <p className="mt-lg-4">
                        Recognize your child's urge to visit another nation,
                        whether it is due to personal or professional reasons.
                        Understanding exactly what your child seeks out in life
                        can help you provide them with them.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="iconbox-item-3 d-flex flex-wrap">
                  <div className="iconbox-border d-flex align-items-center">
                    <div className="iconbox-content ">
                      <h4>Do More Research</h4>
                      <p className="mt-lg-4">
                        It is your responsibility as a parent to research the
                        courses your child is interested in, the university they
                        have been talking about, and the lifestyle they would
                        thrive in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="iconbox-item-3 d-flex flex-wrap">
                  <div className="iconbox-border d-flex align-items-center">
                    <div className="iconbox-content ">
                      <h4>Consider Your Challenges</h4>
                      <p className="mt-lg-4">
                        Get in touch with family members whose children have
                        travelled abroad. Find out more about the challenges
                        you'll undoubtedly encounter and decide how to handle
                        them.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="iconbox-item-3 d-flex flex-wrap">
                  <div className="iconbox-border d-flex align-items-center">
                    <div className="iconbox-content ">
                      <h4>Establish A Budget</h4>
                      <p className="mt-lg-4">
                        You can make the best decision for your family by
                        considering the costs of moving your kids to a foreign
                        nation. But, you need factor in far more charges when
                        sending your child to college in your financial plan
                        than just the cost of school alone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="iconbox-item-3 d-flex flex-wrap">
                  <div className="iconbox-border d-flex align-items-center">
                    <div className="iconbox-content ">
                      <h4>Form A Clear Arrangement</h4>
                      <p className="mt-lg-4">
                        After you fully get what goes into sending your child to
                        a foreign nation, take into account all factors, such as
                        the choice of courses and colleges, the cost, the visa,
                        and so on.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="iconbox-item-3 d-flex flex-wrap">
                  <div className="iconbox-border d-flex align-items-center">
                    <div className="iconbox-content ">
                      <h4>Maintain Regular Contact</h4>
                      <p className="mt-lg-4">
                        You can communicate with your child via a variety of
                        platforms, including email, Skype, Facebook, Viber,
                        WhatsApp, and others. Having said that, it's crucial to
                        avoid utilising them all at once. Let your child alone.
                        Do not immediately freeze when your child is unable to
                        respond to your calls or messages. That can be due to a
                        packed schedule, poor Wi-Fi reception, or shifting time
                        zones.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iconbox-container d-flex flex-wrap justify-content-center">
                <div className="iconbox-item-12 d-flex flex-wrap">
                  <div className="iconbox-border d-flex align-items-center">
                    <div className="iconbox-content ">
                      <h4>Have A Comprehensive Health Examination Performed</h4>
                      <p className="mt-lg-3">
                        Make sure your child has undergone a thorough general
                        physical check-up and dental exam before departing for
                        study abroad (if relevant). Get everyone some weapo ns
                        and the appropriate help. Get your child a spare pair of
                        their prescribed glasses or contacts if they wear
                        either. If your child is prescribed a drug, speak with
                        their primary care physician. They can provide you the
                        names of substitute pharmaceuticals that you can
                        purchase in the nation of your choice. Travel Safety Is
                        Crucial! Sending your child, a great distance away might
                        be understandably unsettling, which is why it is crucial
                        to ensure their mobility security. You must consider
                        travel insurance! Before you depart, make sure your
                        child is safe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ParentGuide;
