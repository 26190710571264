import React from "react";
import { Link } from "react-router-dom";

const ApplicationGuidance = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Application Guidance</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Committed To The Best Results. Join Us Now !
                  </h2>
                  <p className="feature-course-desc">
                    Educasa's staff is committed to expediting the
                    decision-making process and optimising your university
                    application process by closing execution gaps.
                  </p>
                  <p className="feature-course-desc">
                    Our counsellors assist in defining the fundamental elements
                    needed to translate goals into executable strategy. Our
                    experts will also assist with course applications, take into
                    account your academic and extracurricular strengths, check
                    supporting records and documents, and send your application
                    to the appropriate institution or university. Most colleges
                    and institutions respond to inquiries about the status of
                    applications within 6 to 8 weeks, and if you are offered a
                    space in a programme, you will receive a formal offer
                    letter. If your offer letter doesn't contain any
                    requirements you need to fulfil, you can accept the job
                    right away.
                  </p>
                  <p className="feature-course-desc">
                    In some circumstances, you might be required to retake the
                    IELTS test and provide a fresh score, as well as old
                    academic or other documents. In either case, we will support
                    you in segmenting data, reviewing dashboards, and
                    integrating resources to improve your chances of getting
                    accepted into the colleges of your choosing.
                  </p>
                  <p className="mt-lg-4">
                    <b>
                      Please fill out the adjacent form to schedule your free
                      counselling appointment.
                    </b>
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/educator-img33.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ApplicationGuidance;
