import React from "react";

const AdvantagesInternationalStudy = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Advantages of International Study</h3>
            </div>
          </div>
        </div>
      </section>

      <div className="carrer-page-section">
        <div className="container">
          <div className="vacancy-section">
            <div className="section-head text-center">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="career-section-head">
                    <div className="title-divider-center" />
                    <div className="section-disc">
                      <p>
                        Your views will be broadened, and your eyes will be
                        opened to a multicultural atmosphere by studying abroad.
                      </p>
                    </div>
                    <h5 className="section-title">
                      The advantages of studying abroad are numerous, as we
                      shall examine below.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="vacancy-container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="vacancy-content-wrap">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="vacancy-content">
                          <div className="pattern-overlay zigzag-patten" />
                          <h4>Improved Possibilities of Employment</h4>
                          <p>
                            If you have studied abroad, recruiters are more
                            likely to be very interested in you. Employers are
                            certain of your academic and practical mastery of a
                            subject, as well as personality attributes of
                            adaptability and courage, if you have an
                            international degree.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="vacancy-content">
                          <div className="pattern-overlay c-patten" />
                          <h4>Many courses option</h4>
                          <p>
                            You can select from a wide variety of courses,
                            including research- and skill-based ones, when you
                            study abroad. Several new options become available
                            as a result, which you might not always find in your
                            own country. In addition, a lot of universities let
                            you study two things.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvantagesInternationalStudy;
